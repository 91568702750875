import React, { useState, useEffect, useRef, MutableRefObject } from 'react';
import Helmet from 'react-helmet';
import './AsperaFileTransferCalc.scss';
import { FormModal } from 'src/common/utils/FormModal';
import Thanks from './Thanks/Thanks';
import 'src/common/style/common.scss';
import { setPrice as usePrice } from '../hooks/usePrice';
import {
  urxScriptLoader,
  urxScript,
} from 'src/common/hooks/widgets/globalDefinitions';
import { useBPName } from 'src/common/hooks/widgets/useBPName';
import {
  UT30AsperaFileTransferCalc,
  fileSizes,
  networkBandwidths,
  LocationsFrom,
  LocationsTo,
} from '../constants/default';
import { swapCountryAndLanguage } from 'src/common/utils/swapCountryAndLanguage';
import { IBMLocale } from 'src/common/locale/mapValidLocale';
import { ArrowRight20, DocumentDownload20 } from '@carbon/icons-react';
import { initUrxForm } from 'src/common/hooks/widgets/urxForm';
import {
  Button,
  ButtonSet,
  Dropdown,
  NumberInput,
  UnorderedList,
  ListItem,
} from 'carbon-components-react';
import countryList from '../services/currencies.json';
import { useCookiesURL } from '../../../common/hooks/widgets/useCookiesURL';
import {
  useLang,
  LangDefaults,
  determineCountry,
} from 'src/common/hooks/widgets/useLang';
import messages from '../locales/messages';
import { segmentsTracking } from 'src/common/lib/tracker';
import { useEnv } from 'src/common/hooks/widgets/useEnv';
import * as URX_FORM from '../constants/urx-form';
import Cookies from 'js-cookie';
import { createUseTranslation } from '../../../common/hooks/widgets/useTranslation';
import { useAsperaFTSelector } from '../lib/redux/hooks';

export interface AppProps {
  element: Element;
  langCode: string;
  test: boolean;
  countryToTest: string;
}

const AsperaFileTransferCalc = ({
  langCode,
  test,
  countryToTest,
}: AppProps) => {
  const cookieURL = useCookiesURL();
  const localeCode = swapCountryAndLanguage(langCode as IBMLocale);
  const { bpName, companyId, logo, desc, mssp } = useBPName(
    UT30AsperaFileTransferCalc,
    true,
  );
  const [formComplete, setFormComplete] = useState(false);
  const [downloadClicked, setDownloadClicked] = useState(false);
  const [, setCurrency] = useState('');
  const [, setCountry] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [lang, userLocale] = useLang(
    countryList,
    test,
    countryToTest,
    setCurrency,
    setCountry,
    localeCode,
  );
  const [, setBpConsentMail] = useState(false);
  const [fileSize, setFileSize] = useState('1 GB');
  const [networkBandwidth, setNetworkBandwidth] = useState('1 Gbps');
  const [latency, setLatency] = useState(150);
  const [packetLoss, setPacketLoss] = useState(4.1);
  const [transferTo, setTransferTo] = useState('Europe');
  const [transferFrom, setTransferFrom] = useState('India');
  const [throughPut, setThroughPut] = useState('245');
  const [faster, setFaster] = useState('24475');
  const [, setAsperaTransferSpeed] = useState('999');
  const [, setTcpTransferSpeed] = useState('4.1');
  const [asperaTransferTime, setAsperaTransferTime] = useState('');
  const [asperaTransferTimeUnit, setAsperaTransferTimeUnit] = useState('...');
  const [tcpTransferTime, setTcpTransferTime] = useState('');
  const [tcpTransferTimeUnit, setTcpTransferTimeUnit] = useState('...');
  const [aBandwidthUtilization, setABandwidthUtilization] = useState('...');
  const [tBandwidthUtilization, setTBandwidthUtilization] = useState('...');
  const [closeModal, setCloseModal] = useState(false);
  // lang and userlocale is based out of ddo

  const langPaths = [
    '/br-pt/',
    '/mx-es/',
    '/cn-zh/',
    '/id-id/',
    '/jp-ja/',
    '/kr-ko/',
    '/fr-fr/',
    '/de-de/',
    '/it-it/',
    '/es-es/',
    '/sa-ar/',
  ];

  let buyNowLink = 'https://www.ibm.com/products/aspera#pricing';
  const currentPath = window.location.pathname;

  // Create a regex pattern from the browserPaths list.
  const regexPattern = new RegExp(
    `^(${langPaths.map((path) => path.replace(/\//g, '\\/')).join('|')})`,
  );

  if (currentPath && regexPattern.test(currentPath)) {
    const pathMatch = currentPath.match(regexPattern);
    if (pathMatch) {
      buyNowLink =
        'https://www.ibm.com' + pathMatch[0] + 'products/aspera#pricing';
      console.log(pathMatch[0]);
    }
  }
  console.log(buyNowLink);

  const resizeTimeoutRef = useRef<number | null>(null); // Explicitly typing the ref

  /* Have urx form deault in us-en when on language us page */
  const [urxlang, setUrxLang] = useState(
    LangDefaults.localCountryLang as IBMLocale,
  );
  const [localCurrency, setlocalCurrency] = useState<string>(localeCode);
  const [, setToggleCountry] = useState(false);
  const [, setModalLocalCountry] = useState('');
  const [, setModalLocalCurrency] = useState('USD');
  const [, setModalLocalLanguage] = useState('English');
  const [, setLocalExist] = useState(false);
  let localStorageFound = localStorage.getItem('langLocale');
  const latencyRef = useRef() as MutableRefObject<HTMLInputElement>;
  const packetLossRef = useRef() as MutableRefObject<HTMLInputElement>;
  if (localStorageFound == null)
    localStorageFound = LangDefaults.localCountryLang;
  const [, setSelectedLocale] = useState(localStorageFound);
  const AEMMessages = useAsperaFTSelector(
    (state) => state.translations.messages,
  );
  const useTranslation = createUseTranslation(messages, AEMMessages);
  // Determine Country
  determineCountry(
    setLocalExist,
    setSelectedLocale,
    setModalLocalCountry,
    setModalLocalLanguage,
    setModalLocalCurrency,
    setToggleCountry,
    lang,
    countryList,
    true,
    test,
    localeCode,
  );

  useEffect(() => {
    if (downloadClicked) {
      setDownloadClicked(false);
    }
  }, [downloadClicked]);

  useEffect(() => {
    setCookie();
  }, [
    fileSize,
    networkBandwidth,
    latency,
    packetLoss,
    transferFrom,
    transferTo,
  ]);

  useEffect(() => {
    const fetchedUrxLang = localeCode; // us-en
    setUrxLang(userLocale as IBMLocale); // de-de
    if (fetchedUrxLang !== lang) {
      setUrxLang(fetchedUrxLang as IBMLocale); // default us-en
    }
  });

  const equalizeHeights = (elements: any[]) => {
    if (!elements || elements.length === 0) return;
    let tallestHeight = 0;

    // Reset heights to auto before recalculating (to account for responsive changes)
    elements.forEach((element) => {
      element.style.height = 'auto';
    });

    // Determine the tallest element
    elements.forEach((element) => {
      const elementHeight = element.offsetHeight;
      if (elementHeight > tallestHeight) {
        tallestHeight = elementHeight;
      }
    });

    // Set all elements to the tallest height
    elements.forEach((element) => {
      element.style.height = `${tallestHeight}px`;
    });
  };

  useEffect(() => {
    const statiticHeadings = document.querySelectorAll('.statitic_heading');
    equalizeHeights(Array.from(statiticHeadings)); // Run the equalizeHeights function initially

    // Add resize event listener
    const handleResize = () => {
      // Clear any existing timeout
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }

      // Set a new timeout
      resizeTimeoutRef.current = window.setTimeout(() => {
        equalizeHeights(Array.from(statiticHeadings));
      }, 200); // Delay of 200ms
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
    };
  }, []);

  const setCookie = () => {
    const mailCheckbox =
      document.querySelector<HTMLInputElement>('#Q_MAIL_CONSENT');
    setBpConsentMail(!!mailCheckbox?.checked); // set false if unchecked or if undefined
    Cookies.set(
      'urxdcq',
      JSON.stringify({
        source: URX_FORM.FORM_ID,
        // One field to provide all values (stringify)
        dcq: {
          Q_NOTESDATA:
            cookieURL +
            ' | File size: ' +
            fileSize +
            ' | Bandwidth: ' +
            networkBandwidth +
            ' | Transfer From: ' +
            transferFrom +
            ' | Transfer To: ' +
            transferTo +
            ' | Latency: ' +
            latency +
            ' | Packet Loss: ' +
            packetLoss +
            ' | Throughput: ' +
            throughPut +
            ' | Faster: ' +
            faster +
            ' | Aspera file transfer time: ' +
            asperaTransferTime +
            ' | TCP file transfer time: ' +
            tcpTransferTime +
            ' | Aspera bandwidth utilization: ' +
            aBandwidthUtilization +
            ' | TCP bandwidth utilization: ' +
            tBandwidthUtilization +
            ' | FormId:' +
            URX_FORM.FORM_ID +
            (getBp() ? `|Preferred BP:${getBp()}` : ''),
          Q_ASSET_NAME: '', // ISC - Asset Name
        },
      }),
    );
  };
  const getBp = () => {
    if (bpName) return bpName;
    if (logo) return companyId;
    if (desc) return desc;
    if (mssp) return mssp;

    const bpField = document.querySelector('#Q_BPNAME') as HTMLInputElement;
    return bpField ? bpField.value : '';
  };
  useEffect(() => {
    setlocalCurrency(userLocale); // show local Currency
  }, [userLocale, localeCode, localCurrency]);

  const [environment] = useEnv();
  window.urxEnvironment = environment;
  window.onUrxFormSubmitSuccess = () => {
    setFormComplete(!formComplete);
    setDrawerOpen(false);
    setCloseModal(true);
  };
  usePrice(
    fileSize,
    networkBandwidth,
    latency,
    packetLoss,
    transferTo,
    transferFrom,
    setPacketLoss,
    setLatency,
    setThroughPut,
    setFaster,
    setAsperaTransferSpeed,
    setTcpTransferSpeed,
    setAsperaTransferTime,
    setAsperaTransferTimeUnit,
    setTcpTransferTime,
    setTcpTransferTimeUnit,
    setABandwidthUtilization,
    setTBandwidthUtilization,
    userLocale,
  );

  const handleDropdown = (name: any, value: any) => {
    console.log(name);
    name == 'FileSize' && setFileSize(value.label);
    name == 'NetworkBandwidth' && setNetworkBandwidth(value.label);
    name == 'TransferTo' && setTransferTo(value.label);
    if (
      name === 'TransferTo' &&
      value.label !== 'Custom' &&
      transferFrom === 'Custom'
    ) {
      setTransferTo(value.label);
      setTransferFrom(value.label);
    }
    name == 'TransferFrom' && setTransferFrom(value.label);
    if (
      name === 'TransferFrom' &&
      value.label !== 'Custom' &&
      transferTo === 'Custom'
    ) {
      setTransferFrom(value.label);
      setTransferTo(value.label);
    }
    segmentsTracking('CTA Clicked', {
      pageTitle: 'Aspera File Transfer Calculator',
      object: 'Aspera file transfer page',
      resultValue: 'CTA clicked',
      CTA: name,
      location: 'File Transfer Calculator Widget',
      action: '',
      field: '',
    });
  };

  const handleInputChange = (name: any) => {
    console.log(name);
    segmentsTracking('CTA Clicked', {
      pageTitle: 'Aspera File Transfer Calculator',
      object: 'Aspera file transfer page',
      resultValue: name + ' input changed',
      CTA: name,
      location: 'File Transfer Calculator Widget',
      action: '',
      field: '',
    });
  };

  const buyNowButtonLabel = useTranslation('buy_now_button', true);

  return (
    <div
      id="asperafiletransfercalc_widget"
      className="asperafiletransfercalc_widget widget-styles"
    >
      <Helmet>
        <script type="application/javascript" src={urxScriptLoader}></script>
        <script type="application/javascript" src={urxScript}></script>
      </Helmet>
      {/* calculation inputs grid */}
      <div className="bx--grid">
        <div className="bx--row inputs">
          <div className="bx--col-lg-4 bx--col-md-4 bx--col-sm-4">
            <p className="input-label">
              {useTranslation('file_size_dropdown_label')}
            </p>
            <Dropdown
              ariaLabel="Dropdown"
              id="file-size"
              className="calc-input"
              label={String(useTranslation('file_size_dropdown_label'))}
              titleText={''}
              items={fileSizes}
              selectedItem={{ id: fileSize, label: fileSize }}
              onChange={(e) => handleDropdown('FileSize', e.selectedItem)}
              light={true}
            />
            <p className="input-label">
              {useTranslation('network_bandwidth_dropdown_label')}
            </p>
            <Dropdown
              ariaLabel="Dropdown"
              id="network-bandwidth"
              className="calc-input"
              label={String(useTranslation('network_bandwidth_dropdown_label'))}
              titleText={''}
              items={networkBandwidths}
              selectedItem={{ id: networkBandwidth, label: networkBandwidth }}
              onChange={(e) =>
                handleDropdown('NetworkBandwidth', e.selectedItem)
              }
              light={true}
            />
          </div>
          <div className="bx--col-lg-4 bx--col-md-4 bx--col-sm-4">
            <p className="input-label">
              {useTranslation('transfer_from_dropdown_label')}
            </p>
            <Dropdown
              ariaLabel="Dropdown"
              id="transfer-from"
              className="calc-input"
              label={String(useTranslation('transfer_from_dropdown_label'))}
              titleText={''}
              items={LocationsFrom}
              selectedItem={{ id: transferFrom, label: transferFrom }}
              onChange={(e) => {
                handleDropdown('TransferFrom', e.selectedItem);
              }}
              light={true}
            />
            <p className="input-label">
              {useTranslation('transfer_to_dropdown_label')}
            </p>
            <Dropdown
              ariaLabel="Dropdown"
              id="transfer-to"
              className="calc-input"
              label={String(useTranslation('transfer_to_dropdown_label'))}
              titleText={''}
              items={LocationsTo}
              selectedItem={{ id: transferTo, label: transferTo }}
              onChange={(e) => handleDropdown('TransferTo', e.selectedItem)}
              light={true}
            />
          </div>
          <div className="bx--col-lg-4 bx--col-md-4 bx--col-sm-4">
            <p className="input-label">{useTranslation('latency_label')}</p>
            <NumberInput
              id="latency"
              size="md"
              min="0"
              ref={latencyRef}
              allowEmpty={true}
              invalid={false}
              helperText={''}
              className="number-input calc-input"
              value={latency}
              onChange={() => {
                setLatency(Number(latencyRef.current.value));
                setTransferFrom('Custom');
                setTransferTo('Custom');
                handleInputChange(latencyRef.current.id);
              }}
              onBlur={() => {
                null;
              }}
              onFocus={() => {
                null;
              }}
            />
            <p className="input-label">{useTranslation('ploss_label')}</p>
            <NumberInput
              id="packetloss"
              size="md"
              min="0"
              allowEmpty={true}
              invalid={false}
              ref={packetLossRef}
              helperText={''}
              className="number-input calc-input"
              value={packetLoss}
              onChange={() => {
                setPacketLoss(Number(packetLossRef.current.value));
                setTransferFrom('Custom');
                setTransferTo('Custom');
                handleInputChange(packetLossRef.current.id);
              }}
              onBlur={() => {
                null;
              }}
              onFocus={() => {
                null;
              }}
            />
          </div>
        </div>
        <div className="bx--row statitics">
          <div className="bx--col-lg-4 bx--col-md-4 bx--col-sm-4">
            <h3 className="statitic_heading">
              {useTranslation('transfer_time_title')}
            </h3>
            <p className="statitic">
              {asperaTransferTime}
              <span className="smaller">{asperaTransferTimeUnit}</span>
            </p>
            <p className="compared_to">
              {useTranslation('transfer_time_text_1')}
              <span className="highlight">
                {tcpTransferTime} {tcpTransferTimeUnit}
              </span>
              {useTranslation('transfer_time_text_2')}
            </p>
          </div>
          <div className="bx--col-lg-4 bx--col-md-4 bx--col-sm-4">
            <h3 className="statitic_heading">
              {useTranslation('throughput_title')}
            </h3>
            <p className="statitic">{throughPut}x</p>
            <p className="compared_to">{useTranslation('throughput_text')}</p>
          </div>
          <div className="bx--col-lg-4 bx--col-md-4 bx--col-sm-4">
            <h3 className="statitic_heading">
              {useTranslation('bandwidth_utilization_title')}
            </h3>
            <p className="statitic">
              {aBandwidthUtilization}
              <span className="smaller">
                <i style={{ position: 'absolute', visibility: 'hidden' }}>.</i>%
              </span>
            </p>
            <p className="compared_to">
              {useTranslation('transfer_time_text_1')}
              <span className="highlight">{tBandwidthUtilization}%</span>
              {useTranslation('bandwidth_utilization_text_2')}
            </p>
          </div>
          <div className="bx--col-lg-4 bx--col-md-4 bx--col-sm-4">
            <h3 className="statitic_heading">
              {useTranslation('faster_title')}
            </h3>
            <p className="statitic">
              {faster}
              <span className="smaller">
                <i style={{ position: 'absolute', visibility: 'hidden' }}>.</i>%
              </span>
            </p>
          </div>
        </div>
        <div className="bx--row buttons">
          <div className="bx--col-lg-16 bx--col-md-16 bx--col-sm-16">
            <ButtonSet>
              <Button
                id="trial_btn"
                kind="primary"
                className=""
                renderIcon={ArrowRight20}
                onClick={() => {
                  segmentsTracking('CTA Clicked', {
                    pageTitle: 'Aspera File Transfer Page',
                    object: 'File transfer Page',
                    resultValue: 'CTA clicked',
                    CTA: 'Start Trial',
                    location: 'File transfer',
                    action: '',
                    field: '',
                  });
                  initUrxForm(
                    environment,
                    URX_FORM.INSTANCE_ID,
                    URX_FORM.FORM_ID,
                    urxlang as IBMLocale,
                    companyId,
                  );
                  setDrawerOpen(true);
                  setCookie();
                }}
              >
                {useTranslation('button_text')}
              </Button>
              <Button
                renderIcon={ArrowRight20}
                id="buynow_btn"
                kind="tertiary"
                className=""
                href={buyNowLink}
                onClick={() => {
                  // window.open(buyNowLink);
                  segmentsTracking('CTA Clicked', {
                    pageTitle: 'Aspera File Transfer Page',
                    object: 'File transfer Page',
                    resultValue: 'CTA clicked',
                    CTA: 'Buy now',
                    location: 'File transfer',
                    action: '',
                    field: '',
                  });
                }}
              >
                {typeof buyNowButtonLabel == 'undefined'
                  ? 'Buy now'
                  : buyNowButtonLabel}
              </Button>
              <Button
                renderIcon={DocumentDownload20}
                id="whitepaper_btn"
                target="_blank"
                href="https://www.ibm.com/products/aspera/info/transfer-large-files"
                kind="ghost"
                className=""
                onClick={() => {
                  segmentsTracking('CTA Clicked', {
                    pageTitle: 'Aspera File Transfer Page',
                    object: 'File transfer Page',
                    resultValue: 'CTA clicked',
                    CTA: 'Read whitepaper',
                    location: 'File transfer',
                    action: '',
                    field: '',
                  });
                }}
              >
                {useTranslation('whitepaper_button_text')}
              </Button>
            </ButtonSet>
          </div>
        </div>
        <div className="bx--row disclaimer">
          <div className="bx--col-lg-16 bx--col-md-16 bx--col-sm-16">
            <div className="footnote">{useTranslation('disclaimer')}</div>
          </div>
        </div>
      </div>
      {/* results grid */}
      <div className="bx--grid extra-content">
        <div className="bx--row disclaimer">
          <UnorderedList className="something2 ">
            <ListItem>
              <p className="page_body">{useTranslation('additional_text_1')}</p>
            </ListItem>
            <ListItem>
              <p className="page_body">{useTranslation('additional_text_2')}</p>
            </ListItem>
            <ListItem>
              <p className="page_body">{useTranslation('additional_text_3')}</p>
            </ListItem>
          </UnorderedList>
        </div>
      </div>
      {/* modals */}
      <FormModal
        pageTitle="Aspera File Transfer"
        object="Aspera File Transfer Page"
        productTitle={'Aspera File Transfer'}
        pageUrl={''}
        location={'File transfer'}
        bpName={bpName}
        logo={logo}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        formId={URX_FORM.FORM_ID}
        id={URX_FORM.INSTANCE_ID}
        closeModal={closeModal}
        setCloseModal={setCloseModal}
      ></FormModal>
      {formComplete && (
        <Thanks
          fileSize={fileSize}
          networkBandwidth={networkBandwidth}
          transferFrom={transferFrom}
          transferTo={transferTo}
          latency={latency}
          packetLoss={packetLoss}
          asperaTransferTime={asperaTransferTime + asperaTransferTimeUnit}
          bandwidthUtilization={aBandwidthUtilization + '%'}
          throughPut={throughPut + 'x'}
          faster={faster + '%'}
          bpName={bpName}
        />
      )}
    </div>
  );
};

export default AsperaFileTransferCalc;
