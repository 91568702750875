import { useEffect } from 'react';
import { MTU, routeLocations, countryLookUp } from '../constants/default';

export const setPrice = (
  fileSize: string,
  networkBandwidth: string,
  latency: number,
  packetLoss: number,
  trasnferTo: string,
  transferFrom: string,
  setPacketLoss: React.Dispatch<React.SetStateAction<number>>,
  setLatency: React.Dispatch<React.SetStateAction<number>>,
  setThroughPut: React.Dispatch<React.SetStateAction<string>>,
  setFaster: React.Dispatch<React.SetStateAction<string>>,
  setAsperaTransferSpeed: React.Dispatch<React.SetStateAction<string>>,
  setTcpTransferSpeed: React.Dispatch<React.SetStateAction<string>>,
  setAsperaTransferTime: React.Dispatch<React.SetStateAction<string>>,
  setAsperaTransferTimeUnit: React.Dispatch<React.SetStateAction<string>>,
  setTcpTransferTime: React.Dispatch<React.SetStateAction<string>>,
  setTcpTransferTimeUnit: React.Dispatch<React.SetStateAction<string>>,
  setABandwidthUtilization: React.Dispatch<React.SetStateAction<string>>,
  setTBandwidthUtilization: React.Dispatch<React.SetStateAction<string>>,
  userLocale: string,
) => {
  /**
   *
   */
  /**
   * Convert bits per second (bps) to megabits per second (Mbps)
   * @param {number} bps - The value in bits per second
   * @returns {number} The value in megabits per second
   */
  function bpsToMbps(bps: number) {
    return bps / 1e6;
  }

  /**
   * Convert bits per second (bps) to gigabits per second (Gbps)
   * @param {number} bps - The value in bits per second
   * @returns {number} The value in gigabits per second
   */
  function bpsToGbps(bps: number) {
    return bpsToMbps(bps) / 1e3;
  }

  /**
   * Convert megabits per second (Mbps) to bits per second (bps)
   * @param {number} mbps - The value in megabits per second
   * @returns {number} The value in bits per second
   */
  function mbpsToBps(mbps: number) {
    return mbps * 1e6;
  }

  /**
   * Convert megabytes (MB) to bytes (B)
   * @param {number} mb - The value in megabytes
   * @returns {number} The value in bytes
   */
  function mbToB(mb: number) {
    return mb * Math.pow(1024, 2);
  }

  /**
   * Calculate Aspera transfer speed given bandwidth in bps and packet loss percentage
   * @param {number} bandwidthBps - The bandwidth in bits per second
   * @param {number} loss - The packet loss percentage
   * @returns {number} The adjusted bandwidth considering packet loss
   */
  function asperaSpeed(bandwidthBps: number, loss: number) {
    return bandwidthBps - bandwidthBps * (loss / 100);
  }

  /**
   * Calculate TCP Reno transfer speed given bandwidth in bps, packet loss percentage, and latency in milliseconds
   * @param {number} bandwidthBps - The bandwidth in bits per second
   * @param {number} loss - The packet loss percentage
   * @param {number} latencyMs - The latency in milliseconds
   * @returns {number} The TCP Reno transfer speed in bits per second
   */
  function tcpRenoSpeed(bandwidthBps: number, loss: number, latencyMs: number) {
    // Convert MTU to bits and adjust for latency and loss
    const MTU_BITS = MTU * Math.sqrt(1.5) * 8;
    const window = (latencyMs / 1e3) * Math.sqrt(loss / 100);
    const tmpSpeedBps = MTU_BITS / window;
    return Math.min(tmpSpeedBps, bandwidthBps);
  }

  /**
   * Calculate TCP Cubic transfer speed given bandwidth in bps, packet loss percentage, and latency in milliseconds
   * @param {number} bandwidthBps - The bandwidth in bits per second
   * @param {number} loss - The packet loss percentage
   * @param {number} latencyMs - The latency in milliseconds
   * @returns {number} The TCP Cubic transfer speed in bits per second
   */
  function tcpCubicSpeed(
    bandwidthBps: number,
    loss: number,
    latencyMs: number,
  ) {
    const latencySec = latencyMs / 1e3; // Convert latency to seconds
    const lossNum = loss / 100; // Convert loss to a decimal
    // Calculate TCP Cubic specific values
    const tmpL = (14040 * Math.pow(latencySec / lossNum, 0.75)) / latencySec;
    const tmpN = tcpRenoSpeed(bandwidthBps, loss, latencyMs);
    return Math.min(bandwidthBps, Math.max(tmpL, tmpN));
  }

  /**
   * Calculate file transfer time in seconds given transfer speed in bits per second and file size in megabytes
   * @param {number} transferSpeedBitsSec - The transfer speed in bits per second
   * @param {number} fileSizeMb - The file size in megabytes
   * @returns {number} The transfer time in seconds
   */
  function transferTime(transferSpeedBitsSec: number, fileSizeMb: number) {
    // Convert file size to bits
    const fileSizeBits = parseFloat(String(mbToB(fileSizeMb) * 8));
    return parseFloat(String(fileSizeBits / transferSpeedBitsSec));
  }

  // The functions can now be used with the appropriate parameters to calculate transfer speeds and times.
  function convertToMb(fileUnit: any, fileSize: number) {
    if (fileUnit.toLowerCase() === 'gb') {
      return fileSize * 1024;
    }
    if (fileUnit.toLowerCase() === 'tb') {
      return fileSize * 1024 * 1024;
    }
  }

  function convertToMbps(bandwidthUnit: any, bandwidthSize: number) {
    if (bandwidthUnit.toLowerCase() === 'gbps') {
      return bandwidthSize * 1000;
    }
  }

  function round(value: number, precision: number) {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  /* seconds conversion to help with display values */
  interface DurationUnit {
    duration: string;
    unit: string;
  }
  function convertSeconds(seconds: number) {
    const ret: DurationUnit = {
      duration: '0',
      unit: '0',
    };
    if (seconds < 60) {
      ret.duration = String(round(seconds, 1));
      ret.unit = String('sec' + (seconds !== 1 ? 's' : ''));
      return ret;
    }
    if (seconds < 3600) {
      // 60 * 60
      const minutes = round(seconds / 60, 1);
      ret.duration = String(minutes);
      ret.unit = String('min' + (minutes !== 1 ? 's' : ''));
      return ret;
    }
    if (seconds < 86400) {
      // 60 * 60 * 24
      const hours = round(seconds / 3600, 1);
      ret.duration = String(hours);
      ret.unit = String('hour' + (hours !== 1 ? 's' : ''));
      return ret;
    }
    if (seconds < 604800) {
      // 60 * 60 * 24 * 7
      const days = round(seconds / 86400, 1);
      ret.duration = String(days);
      ret.unit = String('day' + (days !== 1 ? 's' : ''));
      return ret;
    }
    if (seconds < 2419200) {
      // 60 * 60 * 24 * 30 (approx. month)
      const weeks = round(seconds / 604800, 1);
      ret.duration = String(weeks);
      ret.unit = String('week' + (weeks !== 1 ? 's' : ''));
      return ret;
    }
    const months = round(seconds / 2419200, 1); // 60 * 60 * 24 * 30
    ret.duration = String(months);
    ret.unit = String('month' + (months !== 1 ? 's' : ''));
    return ret;
  }

  const updateValues = function () {
    if (transferFrom !== 'Custom' && trasnferTo !== 'Custom') {
      let transferFromCode;
      countryLookUp.map((value) => {
        if (value.name == transferFrom) {
          transferFromCode = value.code;
        }
      });
      let transferToCode;
      countryLookUp.map((value) => {
        if (value.name == trasnferTo) {
          transferToCode = value.code;
        }
      });
      const lookup = transferFromCode + '_' + transferToCode;
      let lookupValue = { latency: 100, packetLoss: 2 };
      if (lookup.includes('metro')) {
        lookupValue = routeLocations[0];
      } else {
        routeLocations.map((value) => {
          if (value.country == lookup) {
            lookupValue = value;
          }
        });
      }
      setLatency(lookupValue.latency);
      setPacketLoss(lookupValue.packetLoss);
      calculate(lookupValue.latency, lookupValue.packetLoss);
    }
  };

  function calculate(latencyPassed: number, packetlossPassed: number) {
    //we need to get the value of the filesize and convert it to megabytes
    const fUnit = fileSize.split(' ')[1];
    let fSize = Number(fileSize.split(' ')[0]);
    const bUnit = networkBandwidth.split(' ')[1];
    let bSize = Number(networkBandwidth.split(' ')[0]);

    const pLoss = packetlossPassed;
    const latency1 = latencyPassed;

    if (fUnit.toLowerCase() != 'mb') {
      fSize = Number(convertToMb(fUnit, fSize));
    }

    if (bUnit.toLowerCase() != 'mbps') {
      bSize = Number(convertToMbps(bUnit, bSize));
    }

    // Calculate Aspera transfer speed and time
    const aTransferSpeed = asperaSpeed(mbpsToBps(bSize), pLoss);
    const aTime = transferTime(aTransferSpeed, fSize);
    //we need to determine if we want to use seconds, minutes, hours, days, weeks, months
    /* start testing */
    const asperaConversionObj = convertSeconds(aTime);
    if (typeof asperaConversionObj !== 'string') {
      setAsperaTransferTime(asperaConversionObj.duration);
      setAsperaTransferTimeUnit(asperaConversionObj.unit);
    }
    /* end testing */

    // Calculate TCP Cubic transfer speed and time
    const tTransferSpeed = tcpCubicSpeed(aTransferSpeed, pLoss, latency1);
    const tTime = transferTime(tTransferSpeed, fSize);
    const tcpConversionObj = convertSeconds(tTime);
    if (typeof tcpConversionObj !== 'string') {
      setTcpTransferTime(tcpConversionObj.duration);
      setTcpTransferTimeUnit(tcpConversionObj.unit);
    }
    /* end testing */

    //More Throughput
    setThroughPut(String(Math.round(tTime / aTime)));
    //Percentage Faster
    console.log(
      'tTransferSpeed:',
      Math.round(
        ((bpsToMbps(aTransferSpeed) - bpsToMbps(tTransferSpeed)) /
          bpsToMbps(tTransferSpeed)) *
          100,
      ).toLocaleString(),
    );
    setFaster(
      String(
        Math.round(
          ((bpsToMbps(aTransferSpeed) - bpsToMbps(tTransferSpeed)) /
            bpsToMbps(tTransferSpeed)) *
            100,
        ).toLocaleString(),
      ),
    );
    //File Transfer Speed
    setAsperaTransferSpeed(round(bpsToMbps(aTransferSpeed), 1) + ' Mbps');
    setTcpTransferSpeed(round(bpsToMbps(tTransferSpeed), 1) + ' Mbps');

    //Bandwidth Utilization
    setABandwidthUtilization(
      String(round((bpsToMbps(aTransferSpeed) / bSize) * 100, 1)),
    );
    setTBandwidthUtilization(
      String(round((bpsToMbps(tTransferSpeed) / bSize) * 100, 1)),
    );
  }

  useEffect(() => {
    //We store total of On+Prem VMs for use in the ROI estimator
    //updateValues();
    calculate(latency, packetLoss);
  }, [packetLoss, latency, networkBandwidth, fileSize]);

  useEffect(() => {
    updateValues();
  }, [transferFrom, trasnferTo]);
};
