// URX Embeded Form
// https://github.ibm.com/dgc-web-platform/widget-form

/*
* @todo: Check why it is fetching from stage
https://wwwstage.ibm.com/account/apis/v2.0/forms/formatted/MAIL-qtemaas360/en
https://www.ibm.com/account/apis/v2.0/forms/formatted/MAIL-qtemaas360/en
*/
// Must be the div id where you want the form to be loaded
export const INSTANCE_ID = 'urx-form-asperaftc';
// The required urx form-id to be embedded
export const FORM_ID = 'urx-30538';
