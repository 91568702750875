import { segmentProperties } from '../../lib/tracker';

let urxScript = 'https://www.ibm.com/account/ibmidutil/widget/js/main.js';
let urxScriptLoader =
  'https://www.ibm.com/account/ibmidutil/widget/js/loader.js';

const setupScripts = (main: string, loader: string) => {
  urxScript = main;
  urxScriptLoader = loader;
};

export type RenderFnWithOptions = (
  instanceId: string,
  formId: string,
  langCode: string,
  options: {
    column: number;
    theme: 'light';
    singleStep: boolean;
    triggerManually: boolean;
  },
  cb: (e: HTMLElement) => boolean,
  bpid?: string,
) => Promise<void>;

declare global {
  interface Window {
    // add custom properties and methods
    open(
      url?: string | URL,
      target?: string,
      features?: string,
    ): WindowProxy | null;
    urxEnvironment: string;
    onUrxFormSubmitSuccess: () => void;
    renderUrxWidget: RenderFnWithOptions;
    digitalData: {
      page: {
        attributes: {
          bpid: string;
        };
        category: {
          primaryCategory: string;
          mediaChannel: string;
          ibm: {
            globalBrandTableL10: string;
            gbt10: string;
            globalBrandTableL15: string;
            gbt15: string;
            globalBrandTableL17: string;
            gbt17: string;
            globalBrandTableL20: string;
            gbt20: string;
            globalBrandTableL30: string;
            gbt30: string;
          };
        };
        pageInfo: {
          ibm: {
            messaging: any;
          };
          productTitle: string;
          onsiteSearchResult: number;
          onsiteSearchTerm: string;
        };
      };
      user: {
        location: {
          country: string;
        };
      };
    };
    bluemixAnalytics: {
      trackEvent: (
        eventName: string,
        {
          pageTitle,
          object,
          resultValue,
          CTA,
          location,
          action,
          field,
          subscriptionId,
          productPlanName,
          pageUrl,
        }: segmentProperties,
      ) => void;
    };
    loadedUrxForm: boolean;
  }
}

export { urxScript, urxScriptLoader, setupScripts };
